import {
    Routes,
    Route,
} from 'react-router-dom';
import AddOrUpdateCompany from './add-or-update-company';
import CompaniesOverview from './companies-overview';

export default function CompaniesRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<CompaniesOverview />} />
            <Route exact path="new/" element={<AddOrUpdateCompany />} />
            <Route exact path=":companyId/edit/" element={<AddOrUpdateCompany />} />
        </Routes>
    )
}