import React from 'react';
import {
    makeStyles,
    Grid,
    Dialog,
    DialogContent,
    Button,
} from '@material-ui/core/';
import Title from '../base-components/typography/heading';
import Paragraph from '../base-components/typography/paragraph';
import BaseButton from '../base-components/base-button';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: 20
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10,
        color: theme.palette.primary.main,
        cursor: 'pointer'
    }
}));

export default function PromptDialog({
    open,
    title,
    onHandleClose,
    onClickConfirm
}) {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onHandleClose}
            aria-labelledby="Confirm current step"
            aria-describedby="User can cancel or confirm step"
        >
            <DialogContent className={classes.dialogContent}>
                <Title color="secondary" variant="h6" title={`Confirmation`} />
                <Paragraph
                    description={title}
                    variant="body1"
                    color="secondary"
                    className={classes.bold}
                />
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Button onClick={onHandleClose} style={{ backgroundColor: '#990000', color: 'white' }}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <BaseButton
                            label="Confirm"
                            onClick={onClickConfirm}
                            color="primary"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}