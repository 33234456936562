import { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
// import CheckboxField from "../base-components/form-fields/checkbox-field";
import Title from '../base-components/typography/heading';
import TableOverview from "../table";
import Preloader from "../preloader";
import {
    listUsers,
    // setCustomUserClaim
} from "../../utils/server-functions";
import { getFormattedDate } from "../../utils/date-format";

const useStyles = makeStyles(() => ({
    mainPadding: {
        padding: '90px 15px 15px 15px'
    },
    mt: {
        marginTop: '15px'
    },
}));

// const ClaimsCheckBoxGroup = ({ dataFields }) => {

//     const onChange = async ({ target }) => {
//         const { id, value } = target || {};

//         await setCustomUserClaim({
//             uid: dataFields?.uid,
//             claimId: id,
//             set: value
//         });
//     }

//     const {
//         isAdmin,
//         isJobseeker,
//         isCompany,
//     } = dataFields.claims || {};

//     return (
//         <Grid item xs={12} sm={12} md={12} lg={12}>
//             <CheckboxField
//                 id="isAdmin"
//                 name="isAdmin"
//                 label="Admin"
//                 value={isAdmin || false}
//                 onChange={onChange}
//             />
//             <CheckboxField
//                 id="isJobseeker"
//                 name="isJobseeker"
//                 label="Job seeker"
//                 value={isJobseeker || false}
//                 onChange={onChange}
//             />
//             <CheckboxField
//                 id="isCompany"
//                 name="isCompany"
//                 label="Company"
//                 value={isCompany || false}
//                 onChange={onChange}
//             />
//         </Grid>
//     )
// }

export default function UserAccounts() {
    const classes = useStyles();
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getUsers = async () => {
            const list = await listUsers();
            setUserList(list || []);
            setLoading(false);
        };

        getUsers();
    }, []);

    if (loading) {
        return (
            <div>
                <Preloader height="100vh" />
            </div>
        )
    }

    const users = userList
        .sort((a, b) => b.lastSignIn - a.lastSignIn)
        .map(user => ({
            ...user,
            updated: getFormattedDate(user.updated),
            created: getFormattedDate(user.created),
            lastSignIn: getFormattedDate(user.lastSignIn),
        }))

    return (
        <Grid container spacing={1} direction="column" className={classes.mainPadding}>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Title color="secondary" variant="h3" title="Set Claims" />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                </Grid>
                <TableOverview
                    addIndex
                    data={users}
                    columns={[
                        {
                            headerName: 'ID',
                            fields: ['uid']
                        }, {
                            headerName: 'Display name',
                            fields: ['displayName']
                        }, {
                            headerName: 'Email',
                            fields: ['email']
                        }, {
                            headerName: 'Created',
                            fields: ['created']
                        }, {
                            headerName: 'Last updated',
                            fields: ['updated']
                        }, {
                            headerName: 'Last sign in',
                            fields: ['lastSignIn']
                        },
                        // {
                        //     headerName: 'Last updated',
                        //     fields: ['photoURL']
                        // },
                        // {
                        //     headerName: 'Actions',
                        //     fieldType: 'actionsComponent',
                        //     component: ClaimsCheckBoxGroup
                        // }
                    ]}
                />
            </Grid>
        </Grid>
    )
}