import {
    Grid, makeStyles,
} from '@material-ui/core';
import BaseButton from '../base-components/base-button';
import firebase, { useCollectionData } from '../../utils/firebase';
import { JOBS_COLLECTION } from '../../utils/constants';
import { useCallback, useEffect, useState } from 'react';
import { AutoCompleteField, SelectField, TextField } from '../base-components/form-fields';
import { jobDataConverter } from '../../utils/data-converters';
import { urlify } from '../../utils';
import { getFormattedDateShort } from '../../utils/date-format';

const pageSize = [
    { id: 10, label: '10' },
    { id: 25, label: '25' },
    { id: 50, label: '50' },
    { id: 100, label: '100' },
]

const useStyles = makeStyles(() => ({
    container: {
        marginTop: 100,
        marginLeft: 30,
    }
}));

export default function MoveDuplicateJob() {
    const classes = useStyles();
    const [selectedPageSize, setSelectedPageSize] = useState(pageSize[0].id);
    const [jobID, setJobID] = useState(null);
    const [newJobID, setNewJobID] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const query = useCallback(
        ref => ref
            .limit(selectedPageSize)
            .orderBy('updated', 'desc')
        , [selectedPageSize]);
    const [jobs, jobLoading, jobError] = useCollectionData(JOBS_COLLECTION, jobDataConverter, query);

    useEffect(() => {
        setNewJobID(jobID);
    }, [jobID]);

    const onClickDuplicateMove = async () => {
        const job = jobs.find(j => j.id === jobID);
        const dt = new Date().getTime();

        if (!job) {
            setHasError(true);
            setErrorMessage('No job found');
            return;
        }

        const jobDoc = await firebase.firestore().collection(JOBS_COLLECTION).doc(newJobID).get();
        if (jobDoc.exists) {
            setHasError(true);
            setErrorMessage('ID already exists');
        } else {

            job.updated = dt;
            job.created = dt;
            delete job['id'];

            await firebase.
                firestore().
                collection(JOBS_COLLECTION)
                .withConverter(jobDataConverter)
                .doc(newJobID)
                .set(job)

            console.log('Duplicated/Moved job');
        }
    }

    const onNewIDTextChanged = e => {
        setNewJobID(urlify(e.target.value));
        setHasError(false);
        setErrorMessage('');
    }

    const jobOptions = jobs.map(job => {
        return (
            {
                id: job.id,
                label: `${job?.companyName || ''} - ${job?.title || ''} (${getFormattedDateShort(job?.created)} - ${job?.status})`
            }
        )
    });

    if (jobError) {
        return null;
    }

    return (
        <Grid container spacing={2} direction="row" className={classes.container}>
            <Grid item xs={12} sm={6} md={1} lg={1} >
                <SelectField
                    id="selectedPageSize"
                    name="selectedPageSize"
                    label="Page size"
                    onChange={e => setSelectedPageSize(e.target.value)}
                    value={selectedPageSize}
                    options={pageSize}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} >
                <AutoCompleteField
                    id="jobID"
                    name="jobID"
                    label="Job"
                    value={jobID}
                    options={jobOptions}
                    onChange={e => setJobID(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                    id="newID"
                    name="newID"
                    label="New ID"
                    value={newJobID}
                    disabled={jobLoading}
                    hasError={hasError}
                    errorMessage={errorMessage}
                    onChange={onNewIDTextChanged}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <BaseButton
                    variant="contained"
                    color="secondary"
                    onClick={onClickDuplicateMove}
                    disabled={jobLoading || !newJobID || hasError}
                    label={`Duplicate/Move`}
                >
                </BaseButton>
            </Grid>
        </Grid>
    )
}