import {
    makeStyles,
    withStyles,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core/';
import Paragraph from '../base-components/typography/paragraph';
import TableBodyDynamic from './table-body';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.light,
        textTransform: 'uppercase',
        borderRight: '1px solid rgba(255,255,255,0.3)',
        fontSize: 14,
        lineHeight: '28px',
    },
    body: {
        borderRight: '1px solid rgba(0,0,0,0.1)',
        fontSize: 14,
        lineHeight: '28px',
    },
}))(TableCell);

const useStyles = makeStyles(() => ({
    container: {
        overflowX: 'auto',
    },
    table: {
        borderLeft: '1px solid rgba(0,0,0,0.1)',
    },
    tableRow: {
        transition: 'all 0.2s linear',
        '&:hover': {
            backgroundColor: '#F5f5f5',
        }
    },
    listItem: {
        '&.MuiTypography-displayBlock': {
            marginBottom: '0 !important'
        }
    },
    customIcon: {
        width: 25,
        height: 25,
        marginRight: 8
    },
    actionColumn: {
        width: '60px'
    }
}));

export default function TableOverview({ data = [], columns = [], addIndex = false, type, extraDataProps }) {
    const classes = useStyles();

    if (data?.length === 0) {
        return (
            <div style={{ textAlign: 'center' }}>
                <Paragraph
                    description='No data available'
                    variant="body1"
                    color="secondary"
                />
            </div>
        )
    }

    const lengthOfColumns = columns.length;

    return (
        <TableContainer className={classes.container}>
            <Table className={classes.table} aria-label="table jobs">
                <TableHead>
                    <TableRow>
                        {
                            addIndex && (
                                <StyledTableCell
                                    key={`header-name-index`}
                                    align="left"
                                    style={{ width: "60px" }}
                                >
                                    #
                                </StyledTableCell>
                            )}
                        {
                            extraDataProps &&
                            <StyledTableCell className={classes.actionColumn} />
                        }
                        {
                            columns?.map(({ headerName }, i) =>
                                <StyledTableCell
                                    key={`header-name-${i}`}
                                    className={classes.headerTitle}
                                    align="left"
                                    style={{ width: `calc(100% / ${lengthOfColumns})` }}
                                >
                                    {headerName}
                                </StyledTableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                {
                    data?.length > 0 &&
                    <TableBody>
                        <TableBodyDynamic
                            data={data}
                            row={columns}
                            addIndex={addIndex}
                            nrOfColumns={lengthOfColumns}
                            extraDataProps={extraDataProps}
                            type={type}
                        />
                    </TableBody>
                }
            </Table>
        </TableContainer>
    )
}