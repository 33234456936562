import {
    Box
} from '@material-ui/core';
import TableOverview from '../../components/table';
import { connectStateResults, } from 'react-instantsearch-dom';
import Preloader from '../../components/preloader';

const Loading = connectStateResults(({ isSearchStalled }) => <Box sx={{ width: '100%' }}>{isSearchStalled ? <Preloader /> : null}</Box>);

export default function TypesenseResultsTable({ hits = [], filterSortMapData, ...props }) {
    let data = filterSortMapData ? filterSortMapData(hits) : hits;

    return (
        (data?.length > 0 ? <TableOverview data={data} {...props} /> : <Loading />)
    )
}