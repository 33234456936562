import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {
    makeStyles,
    withStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PromptDialog from '../../promp-dialog';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#b6cbe1',
        color: theme.palette.common.black,
        borderRight: '1px solid rgba(255,255,255,0.3)',
        fontSize: 12,
        fontWeight: 'bold'
    },
    body: {
        borderRight: '1px solid rgba(0,0,0,0.1)',
        fontSize: 12
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    container: {
        overflowX: 'auto',
        marginTop: 30,
        marginBottom: 15
    },
    table: {
        borderLeft: '1px solid rgba(0,0,0,0.1)',

        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',

            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    tableRow: {
        transition: 'all 0.2s linear',
        '&:hover': {
            backgroundColor: '#F5f5f5',
        }
    },
    customIcon: {
        width: 25,
        height: 25,
        marginRight: 8
    },
    actionColumn: {
        width: '100px'
    },
    fileSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    iconFileUpload: {
        marginRight: 10
    }
}));

const StorageLink = ({ item }) => {
    const [downloadURL, setDownloadURL] = useState('');
    useEffect(() => {
        const fetchDownloadURL = async () => {
            if (item?.getDownloadURL) {
                const url = await item.getDownloadURL();
                setDownloadURL(url)
            }
        }
        fetchDownloadURL();
    }, [item]);
    return (
        <a href={downloadURL} target="_blank" rel="noreferrer">{item.name}</a>
    )
}


export default function TableList({ filesList, onDeleteFile }) {
    const classes = useStyles();
    const [promtDelete, setPromptDelete] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    if (filesList?.length === 0) {
        return null;
    }

    const promptConfirmDelete = (item) => {
        setItemToDelete(item);
        setPromptDelete(true);
    }

    const deleteConfirmed = () => {
        onDeleteFile(itemToDelete)
        setItemToDelete(null);
        setPromptDelete(false);
    }

    return (
        <TableContainer component={Paper} className={classes.container}>
            <Table aria-label="table uploads" className={classes.table} >
                <TableHead>
                    <TableRow>
                        <StyledTableCell>File</StyledTableCell>
                        <StyledTableCell className={classes.actionColumn}>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        filesList?.map((item, i) => {
                            return (
                                <TableRow key={`storage-item-${i}`}>
                                    <TableCell>
                                        <div className={classes.fileSection}>
                                            <DescriptionOutlinedIcon className={classes.iconFileUpload} />
                                            <StorageLink item={item} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Link to="#" onClick={() => promptConfirmDelete(item)}>
                                            <Tooltip title={`Delete file`} aria-label="delete" placement="top" arrow>
                                                <DeleteIcon color="error" />
                                            </Tooltip>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
            {
                promtDelete &&
                <PromptDialog
                    open={promtDelete}
                    onHandleClose={() => setPromptDelete(false)}
                    onClickConfirm={deleteConfirmed}
                    title={`Delete "${itemToDelete.name}" from storage?`}
                />
            }
        </TableContainer>
    )
}