import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    makeStyles,
    withStyles,
    TableCell,
    TableRow,
    IconButton,
    Collapse,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    Tooltip,
} from '@material-ui/core/';
import { green } from '@material-ui/core/colors';
import {
    ArrowDropDown,
    ArrowDropUp,
    Edit,
    Delete,
    Link as LinkIcon,
    CloudDownload,
    Done,
} from '@material-ui/icons';
import { getFormattedDate } from '../../utils/date-format';
import firebase from '../../utils/firebase';
import PromptDialog from '../promp-dialog';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#b6cbe1',
        color: theme.palette.secondary.dark,
        borderRight: '1px solid rgba(255,255,255,0.3)',
        fontSize: 14,
        lineHeight: '28px',
    },
    body: {
        borderRight: '1px solid rgba(0,0,0,0.1)',
        fontSize: 14,
        lineHeight: '28px',
        color: theme.palette.secondary.main,
        verticalAlign: 'center',
        position: 'relative',
    },
}))(TableCell);

const useStyles = makeStyles(() => ({
    container: {
        overflowX: 'auto'
    },
    table: {
        borderLeft: '1px solid rgba(0,0,0,0.1)',
    },
    tableRow: {
        transition: 'all 0.2s linear',
        backgroundColor: "#FFF",
        '&:hover': {
            backgroundColor: '#F5f5f5',
        }
    },
    listItem: {
        '&.MuiTypography-displayBlock': {
            marginBottom: '0 !important'
        }
    },
    collapsedContainer: {
        backgroundColor: 'rgba(192,192,192,0.1)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
        border: '1px solid rgba(0,0,0,0.1)'
    },
    link: {
        marginRight: 10
    }
}));

export default function DynamicTableBody({
    data = [],
    addIndex = false,
    row,
    nrOfColumns,
    extraDataProps,
    type,
    onItemChange
}) {
    const classes = useStyles();
    const [open, setOpen] = useState(null);
    const [selected, setSelected] = useState(null);
    const [selectedCopy, setSelectedCopy] = useState(null);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    const totalColumns = nrOfColumns + 1;

    const onConfirmDelete = id => {
        setShowDeletePrompt(true);
        setSelected(id);
    }

    const onClickConfirmDelete = async (e) => {
        setShowDeletePrompt(false);
        await firebase.firestore().collection(type).doc(selected).update({ status: 'trash' });
    }

    const onHandleClose = () => {
        setShowDeletePrompt(false);
    };

    const onCopyLink = ({ id, externalLink }) => {
        setSelectedCopy(id);
        if (process.env.NODE_ENV === 'development') {
            externalLink = externalLink.replace('https://mikiertraha.com/', 'https://staging.mikiertraha.com/')
        }
        navigator.clipboard.writeText(externalLink);
    }

    const onDownloadSEOImage = ({ id }) => {
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = `https://us-central1-mi-kier-traha.cloudfunctions.net/getJobSEOImage/?id=${id}`;
        // link.download = file.name;

        // It needs to be added to the DOM so it can be clicked
        document.body.appendChild(link);
        link.click();

        // To make this work on Firefox we need to wait
        // a little while before removing it.
        setTimeout(() => {
            if (window.URL) {
                window.URL.revokeObjectURL(link.href);
            }
            link.parentNode.removeChild(link);
        }, 0);
    }

    return data?.map((dataFields, i) => {

        const handleExpandClick = (i) => {
            setOpen(open === i ? -1 : i);
        };

        return (
            <>
                <TableRow key={`jobs-${dataFields.id}-${i}`} className={classes.tableRow}>
                    {extraDataProps &&
                        <StyledTableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => handleExpandClick(i)}>
                                {open === i ? <ArrowDropUp color="primary" /> : <ArrowDropDown color="primary" />}
                            </IconButton>
                        </StyledTableCell>
                    }
                    {type !== "outboundClientRequest" && addIndex && (
                        <StyledTableCell align="left" key={`table-row-item-${i}`}>
                            {i + 1}
                        </StyledTableCell>
                    )}
                    {
                        row?.map(({ fieldType, fields, component, componentProps }, columnIndex) => {
                            if (fieldType === "actionsComponent") {
                                const TemplateComponent = component;
                                return (
                                    <StyledTableCell
                                        align="left"
                                        key={`table-row-item-${i}-${columnIndex}`}
                                    >
                                        <TemplateComponent
                                            rowIndex={i}
                                            type={type}
                                            dataFields={dataFields}
                                            onItemChange={onItemChange}
                                            {...componentProps}
                                        />
                                    </StyledTableCell>
                                );
                            }

                            return (
                                <StyledTableCell align="left" key={`table-row-item-${i}-${columnIndex}`}>
                                    {
                                        fields?.map((propName, i) => {

                                            let valueField = dataFields[propName];

                                            if (['created', 'updated', 'publishDate', 'unpublishDate'].includes(propName)) {
                                                return (
                                                    <>
                                                        {getFormattedDate(dataFields[propName])}
                                                    </>
                                                )
                                            }
                                            if (propName === 'branches') {
                                                const branches = [dataFields[propName]];
                                                return (
                                                    <>
                                                        {branches.join(' - ')}
                                                    </>
                                                )
                                            }
                                            if (propName === 'actions') {
                                                return (
                                                    <>
                                                        <Link to={`/admin/${type}/${dataFields.id}/edit`} className={classes.link}>
                                                            <Tooltip title={`Edit`} aria-label="edit" placement="top" arrow>
                                                                <Edit color="primary" fontSize="small" />
                                                            </Tooltip>
                                                        </Link>
                                                        {
                                                            type === 'jobs' &&
                                                            <Link to="#" onClick={() => onCopyLink(dataFields)} className={classes.link}>
                                                                <Tooltip title={`Copy link`} aria-label="copy-link" placement="top" arrow>
                                                                    {
                                                                        selectedCopy === dataFields.id
                                                                            ?
                                                                            <Done style={{ color: green[500] }} fontSize="small" />
                                                                            :
                                                                            <LinkIcon color="primary" fontSize="small" />
                                                                    }
                                                                </Tooltip>
                                                            </Link>
                                                        }
                                                        {
                                                            type === 'jobs' &&
                                                            <Link to="#" onClick={() => onDownloadSEOImage(dataFields)} className={classes.link}>
                                                                <Tooltip title={`Download`} aria-label="download-link" placement="top" arrow>
                                                                    <CloudDownload color="primary" fontSize="small" />
                                                                </Tooltip>
                                                            </Link>
                                                        }
                                                        <Link to="#" onClick={() => onConfirmDelete(dataFields.id)} className={classes.link}>
                                                            <Tooltip title={`Delete`} aria-label="delete" placement="top" arrow>
                                                                <Delete color="error" fontSize="small" />
                                                            </Tooltip>
                                                        </Link>
                                                    </>
                                                );
                                            }

                                            return (
                                                <>
                                                    {valueField}
                                                </>
                                            )
                                        })
                                    }
                                </StyledTableCell>
                            )
                        })
                    }
                </TableRow>
                {extraDataProps && open === i &&
                    <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={totalColumns} className={classes.collapsedContainer}>
                            <Collapse in={open === i} timeout="auto" unmountOnExit>
                                <Box margin={3}>
                                    <TableContainer className={classes.container}>
                                        <Table className={classes.table} aria-label="table jobs extra fields">
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        extraDataProps?.map(({ headerName }, i) =>
                                                            <StyledTableCell
                                                                key={`header-name-extra-fields-${i}`}
                                                                className={classes.headerTitle}
                                                                align="left"
                                                                style={{ width: `calc(100% / ${extraDataProps.length})` }}
                                                            >
                                                                {headerName}
                                                            </StyledTableCell>
                                                        )
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    extraDataProps?.map(({ fields }, i) => {

                                                        return (
                                                            <StyledTableCell align="left" key={`table-row-item-${i}`}>
                                                                {
                                                                    fields?.map((propName, i) => {

                                                                        let valueField = dataFields[propName];
                                                                        return (
                                                                            <>
                                                                                {valueField}
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </StyledTableCell>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                }
                {
                    showDeletePrompt &&
                    <PromptDialog
                        open={showDeletePrompt}
                        onHandleClose={onHandleClose}
                        onClickConfirm={onClickConfirmDelete}
                        title={`Delete item?`}
                    />
                }
            </>
        )
    })
}