import {
    Routes,
    Route,
} from 'react-router-dom';
import AddOrUpdateAd from './add-or-update-ad';
import AdsOverview from './ads-overview';

export default function AdsRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<AdsOverview />} />
            <Route exact path="/new/" element={<AddOrUpdateAd />} />
            <Route exact path="/:adId/edit/" element={<AddOrUpdateAd />} />
        </Routes>
    )
}