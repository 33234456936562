import {
    Grid,
} from '@material-ui/core';
import AutoCompleteField from '../../base-components/form-fields/autocomplete-field';
import DatePickerField from '../../base-components/form-fields/datepicker-field';
import RadioField from '../../base-components/form-fields/radio-field';
import { statusOptions } from '../../../utils/constants';
import BaseButton from '../../base-components/base-button';

export default function Filter({
    status,
    setStatus,
    updateStatus,
    disableUpdateStatus,
    startDt,
    setStartDt,
    endDt,
    setEndDt,
}) {

    return (
        <Grid container direction="row" alignItems="center">
            {/* <Grid item xs={12} sm={12} md={12} lg={5}>
                <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <RadioField
                            id="status"
                            name="status"
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                            options={[...statusOptions, { id: 'all', label: 'All', val: 'all' },]}
                        />
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DatePickerField
                            id="startDt"
                            label="Start date"
                            name="startDt"
                            value={startDt}
                            onChange={e => setStartDt(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <DatePickerField
                            id="endDt"
                            label="End date"
                            name="endDt"
                            value={endDt}
                            onChange={e => setEndDt(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
                <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <AutoCompleteField
                            id="status"
                            name="status"
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                            options={statusOptions}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <BaseButton
                            variant="contained"
                            color="primary"
                            onClick={updateStatus}
                            label={`Batch Update`}
                            disabled={disableUpdateStatus}
                        >
                        </BaseButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}