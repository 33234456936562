import {
    Routes,
    Route,
} from 'react-router-dom';
import AddOrUpdateJob from './add-or-update-job';
import JobsOverview from './jobs-overview';

export default function JobsRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<JobsOverview />} />
            <Route exact path="new/" element={<AddOrUpdateJob />} />
            <Route exact path=":jobId/edit/" element={<AddOrUpdateJob />} />
        </Routes>
    )
}