export const extractDateFromTime = timestamp => {
    const dt = new Date(timestamp);
    const dateString = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()} 00:00`;
    return new Date(dateString).getTime();
}

export const getNextDayAtMidnight = time => {
    // Create a new Date object from the given epoch time
    const date = new Date(time);
    // Set the hour, minutes, and seconds to 0
    date.setHours(0, 0, 0, 0);
    // Add 1 day to the date object
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
    // Return the number of milliseconds since the Unix epoch
    return date.getTime();
}