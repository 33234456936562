import {
    Routes,
    Route,
} from 'react-router-dom';
import DashboardAdmin from './dashboard-admin';
import DashboardJobseeker from './dashboard-jobseeker';
import JobsRoutes from '../jobs/jobs-routes';
import CompaniesRoutes from '../companies/companies-routes';
import AdsRoutes from '../ads/ads-routes';
import BatchUpdate from './batch-update';
import ImportExport from './import-export';
import UserAccounts from './user-accounts';

import { IS_ADMIN, IS_JOBSEEKER } from '../../utils/constants';
import { useContext } from 'react';
import { Context } from '../../utils/context-provider';

// Define an object to map claim keys to corresponding components
const claimComponentMap = {
    [IS_ADMIN]: DashboardAdmin,
    [IS_JOBSEEKER]: DashboardJobseeker,
    // Add new claims and corresponding components here
};

// const isUserAdmin = (claims) => {
//     if (claims) {
//         const claimKeys = Object.keys(claims);
//         return (claimKeys.indexOf(IS_ADMIN) > -1 && claimKeys[claimKeys.indexOf(IS_ADMIN)] === IS_ADMIN);
//     }
//     return false;
// }

const renderComponentBasedOnRole = (claims) => {
    if (claims) {
        const claimKeys = Object.keys(claims)
        // Loop through the claim keys and check if the user has any of the required claims
        for (const claimKey of claimKeys) {
            if (claims[claimKey] === true && claimComponentMap[claimKey]) {
                // If the user has the claim and a corresponding component is defined, render the component
                return claimComponentMap[claimKey];
            }
        }
    }
    return null;
};

export default function DashboardRoutes() {
    const { user, loadingAuthState, loadingUserData } = useContext(Context);
    const DashboardComponent = renderComponentBasedOnRole(user?.claims)
    if (loadingAuthState || loadingUserData || !DashboardComponent) return null;

    return (
        <Routes>
            <Route exact path="/" element={<DashboardComponent />} />
            <Route exact path="import-export/" element={<ImportExport />} />
            <Route path="user-accounts/" element={<UserAccounts />} />
            <Route path="/batch-update" element={<BatchUpdate />} />
            <Route path="jobs/*" element={<JobsRoutes />} />
            <Route path="companies/*" element={<CompaniesRoutes />} />
            <Route path="ads/*" element={<AdsRoutes />} />
        </Routes>
    )
}