import { useNavigate } from 'react-router';
import {
    makeStyles,
    Grid,
    Box
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import {
    connectSearchBox,
    connectHits
} from "react-instantsearch-dom";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

import BaseButton from '../base-components/base-button';
import Heading from '../base-components/typography/heading';
import TypesenseSearchBox from '../../utils/search/typesense-search-box-overview';
import TypesenseResultsTable from "../../utils/search/typesense-results-table";
import TypesenseSearchInterface from "../../utils/search/typesense-search-interface";
import { searchServerConfig } from '../../utils/search/typesense-config';
import TypesensePaging from '../../utils/search/typesense-paging';

const SearchBox = connectSearchBox(TypesenseSearchBox);
const ResultsTable = connectHits(TypesenseResultsTable);

const searchParameters = {
    'query_by':
        'title,' +
        'companyName,' +
        'description,' +
        'service,' +
        /*
            'country,' +
            'createdText,' +
        */
        '',
    'sort_by':
        '_text_match:desc,publishDate:desc',
    'filter_by': 'status:!=trash',
};

const useStyles = makeStyles(() => ({
    mainPadding: {
        padding: '90px 15px 15px 15px'
    },
    mt: {
        marginTop: '15px'
    },
}));

export default function JobsOverview() {
    const classes = useStyles();
    const navigate = useNavigate();

    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: searchServerConfig,
        additionalSearchParameters: searchParameters,
    });
    const searchClient = typesenseInstantsearchAdapter.searchClient;

    const jobsTableHead = [
        { headerName: 'Title', fields: ['title'] },
        { headerName: 'Company', fields: ['companyName'] },
        { headerName: 'Company email', fields: ['companyEmail'] },
        { headerName: 'Country', fields: ['countryText'] },
        { headerName: 'Service', fields: ['service'] },
        { headerName: 'Publish date', fields: ['publishDate'] },
        { headerName: 'Unpublish date', fields: ['unpublishDate'] },
        { headerName: 'Status', fields: ['status'] },
        { headerName: 'Actions', fields: ['actions'] },
    ];

    return (
        <Grid container direction="column" className={classes.mainPadding}>
            <TypesenseSearchInterface
                indexName={"jobs"}
                client={searchClient}
                hitsPerPage={50}
            >
                <Grid item xs={12} container justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                        <Heading color="secondary.dark" variant="h3">Job Listings</Heading>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box display="flex" justifyContent="flex-end">
                            <SearchBox placeholderText="Search jobs" />
                            <BaseButton onClick={() => navigate('/admin/jobs/new')} color="primary" label="Add New Job" startIcon={<AddCircle />} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} container className={classes.mt}>
                    <Grid item container xs={12} sm={12} md={12} lg={12} alignItems="center">
                        <TypesensePaging padding={1} showLast />
                    </Grid>
                </Grid>
                <Grid item xs={12} container className={classes.mt}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ResultsTable
                            columns={jobsTableHead}
                            type="jobs"
                            addIndex={true}
                        />
                    </Grid>
                </Grid>
            </TypesenseSearchInterface>
        </Grid>
    )
}