import {
    Button,
    makeStyles,
} from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
    width: '100%',
    deleteButton: {
        backgroundColor: '#F21F0C',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#911207'
        }
    },
}));

const BaseButton = ({
    color = "primary",
    deleteButton = false,
    label = '',
    disabled = false,
    startIcon = null,
    endIcon = null,
    title = '',
    href = null,
    onClick = () => { },
}) => {
    const classes = useStyles();
    return (
        <Button
            href={href}
            title={title}
            color={color}
            onClick={onClick}
            disabled={disabled}
            className={deleteButton === true ? classes.deleteButton : undefined}
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {label}
        </Button>
    )
}

export default BaseButton;