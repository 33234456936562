import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    makeStyles,
    Grid,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Heading from '../base-components/typography/heading';
import TextField from '../base-components/form-fields/text-field';
import FileField from '../base-components/form-fields/file-field';
import TableList from '../base-components/table-list';
import DatePickerField from '../base-components/form-fields/datepicker-field';
import firebase, { useDocumentData } from '../../utils/firebase';
import BaseButton from '../base-components/base-button';
// import PromptDialog from '../promp-dialog';
import { adDataConverter } from '../../utils/data-converters';
import Preloader from '../preloader/';
import { ADS_COLLECTION } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: '100%'
    },
    mainPadding: {
        padding: '120px 60px 60px 60px'
    },
    container: {
        paddingRight: 30,
        flexDirection: 'column',
    },
    floatingBox: {
        backgroundColor: '#FFFFFF',
        padding: 30,
        boxShadow: '-7px 7px 20px #00000029',
        width: '100%',
        position: 'sticky',
        top: 110,
        height: 375,
        [theme.breakpoints.down('lg')]: {
            height: 425
        },
    },
    buttons: {
        width: '100%',
    }
}));

export default function AddOrUpdateAd() {
    const classes = useStyles();
    const { adId } = useParams();
    const navigate = useNavigate();
    const [data, loading, error, onChange] = useDocumentData(ADS_COLLECTION, adId, adDataConverter);
    const [saving, setSaving] = useState(false);
    const [hasErrors] = useState(false);
    // const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [refreshList, setRefreshList] = useState(true);
    const [filesList, setFilesList] = useState([]);
    const [imgSrc, setImgSrc] = useState(null);

    const storage = firebase.storage();
    const storagePath = `/uploads/ads/${adId}/`;
    const listRef = storage.ref(storagePath);

    useEffect(() => {

        const getFiles = async () => {
            if (refreshList) {
                setRefreshList(false);
                try {
                    const res = await listRef.listAll();
                    if (res.items?.length > 0) {
                        const item = res.items[0]
                        const url = await item.getDownloadURL();
                        setFilesList(res.items);
                        setImgSrc(url)
                    } else {
                        setFilesList([]);
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }
        getFiles();

    }, [refreshList, listRef]);

    const save = async () => {
        setSaving(true);
        try {
            if (!hasErrors) {
                const {
                    id,
                    ...ad
                } = data;

                await firebase
                    .firestore()
                    .collection(ADS_COLLECTION)
                    .withConverter(adDataConverter)
                    .doc(id)
                    .set({ ...ad, imgSrc }, { merge: true });
                if (!adId) {
                    navigate(`/admin/ads/${id}/edit`)
                }
                setRefreshList(true);
            }
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
    }

    const onDeleteFile = async item => {
        if (item?.delete) {
            try {
                await item.delete()
                console.log('deleted file from storage');
                if (adId) {
                    firebase.firestore().collection(ADS_COLLECTION).doc(adId).update({ imgSrc: null })
                }
                setRefreshList(true);
            }
            catch (error) {
                console.error(error)
            }
        }
    }

    if (loading) {
        return <Preloader />
    }
    if (error) {
        return <p>Something went wrong, please try again later</p>
    }

    const labelText = adId ? 'Save' : 'Publish';
    const labelTextSaving = adId ? 'Saving...' : 'Publishing...';

    return (
        <>
            <Grid container direction="column" className={classes.mainContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.mainPadding}>
                        <Grid container direction="column">
                            <Grid item xs={12} container>
                                <Grid item xs={12} sm={12} md={12} lg={9}>
                                    <Heading color="secondary.dark" variant="h1">{`${adId ? 'UPDATE' : 'NEW'} AD`}</Heading>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <BaseButton
                                        label={saving ? labelTextSaving : labelText}
                                        onClick={save}
                                        color="primary"
                                        endIcon={<CloudUploadIcon />}
                                        className={classes.buttons}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container justifyContent="space-between">
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Heading color="secondary.dark" variant="h6">Ad Details</Heading>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container direction="column">
                                        <Grid container spacing={2} direction="row">
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <TextField
                                                    id="title"
                                                    name="title"
                                                    label="Title"
                                                    value={data.title || data.label || ''}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <TextField
                                                    id="position"
                                                    name="Position"
                                                    label="position"
                                                    value={data.position || data.label || ''}
                                                    onChange={onChange}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <DatePickerField
                                                    id="publishDate"
                                                    label="Publish Date"
                                                    name="publishDate"
                                                    value={data.publishDate || null}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <DatePickerField
                                                    id="unpublishDate"
                                                    label="Unpublish Date"
                                                    name="unpublishDate"
                                                    value={data.unpublishDate || null}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <TextField
                                                    id="externalLink"
                                                    name="externalLink"
                                                    label="External Link"
                                                    value={data.externalLink || ''}
                                                    onChange={onChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                {
                                                    filesList?.length === 0 ?
                                                        <FileField
                                                            id="upload-file-input"
                                                            name="upload-file-input"
                                                            accept=".jpeg, .jpg, .png, .gif"
                                                            storagePath={storagePath}
                                                            labelButton="Upload ad"
                                                            setRefreshList={setRefreshList}
                                                        />
                                                        :
                                                        <TableList
                                                            filesList={filesList}
                                                            onDeleteFile={onDeleteFile}
                                                        />
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={3} spacing={2}>
                                                <BaseButton
                                                    label={saving ? labelTextSaving : labelText}
                                                    onClick={save}
                                                    color="primary"
                                                    endIcon={<CloudUploadIcon />}
                                                    className={classes.buttons}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            {
                // showDeletePrompt &&
                // <PromptDialog
                // open={showDeletePrompt}
                // onHandleClose={onHandleClose}
                // onClickConfirm={onClickConfirmDelete}
                // title={`Delete ad?`}
                // />
            }
        </>
    )
}