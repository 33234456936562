import { useContext } from 'react';
import {
    Grid, makeStyles,
} from '@material-ui/core';
import BaseButton from '../base-components/base-button';
import firebase from '../../utils/firebase';
import { jobDataConverter } from '../../utils/data-converters';
import { getFormattedDate } from '../../utils/date-format';
import { countryOptions, serviceOptions } from '../../utils/constants';

const JOB_COLLECTION = 'jobs';
const JOB_LABEL = 'Jobs';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: 100,
        marginLeft: 30,
    }
}));

const getTypsenseDoc = (jobData) => {

    delete jobData.seoImage;

    const {
        companyName,
        companyEmail,
        country,
        created,
        description,
        newDescription,
        publishDate,
        service,
        unpublishDate,
        updated,
        ...rest
    } = jobData;

    return {
        ...rest,
        companyName: companyName || '',
        companyEmail: companyEmail || '',
        country: country || 'aua',
        countryText: countryOptions.find(c => c.id === country)?.label || 'Aruba',
        created: created || 0,
        createdText: getFormattedDate(created),
        description: newDescription || description || '',
        publishDate: publishDate || 0,
        publishDateText: getFormattedDate(publishDate),
        service: serviceOptions.find(c => c.id === service)?.label || 'Public',
        unpublishDate: unpublishDate || 0,
        unpublishDateText: getFormattedDate(unpublishDate),
        updated: updated || 0,
        updatedText: getFormattedDate(updated),
    }
}

export default function TypesenseJobs({ client }) {
    const classes = useStyles();

    const onClickCreate = async () => {
        const collection = {
            'name': JOB_COLLECTION,
            'fields': [
                { name: 'companyName', type: 'string' },
                { name: 'companyEmail', type: 'string' },
                { name: 'country', type: 'string' },
                { name: 'created', type: 'int64' },
                { name: 'createdText', type: 'string' },
                { name: 'description', type: 'string' },
                { name: 'publishDate', type: 'int64' },
                { name: 'publishDateText', type: 'string' },
                { name: 'service', type: 'string' },
                { name: 'status', type: 'string' },
                { name: 'title', type: 'string' },
                { name: 'unpublishDate', type: 'int64' },
                { name: 'unpublishDateText', type: 'string' },
                { name: 'updated', type: 'int64' },
                { name: 'updatedText', type: 'string' },
            ],
            'default_sorting_field': 'publishDate'
        };

        try {
            const resp = await client.collections().create(collection);
            console.log(resp);
        }
        catch (error) {
            console.error(error);
        }
    }
    const onClickIndex = async () => {
        try {
            const querySnapshot = await firebase
                .firestore()
                .collection(JOB_COLLECTION)
                .withConverter(jobDataConverter)
                .where('status', '!=', 'trash')
                // .limit(5)
                .get();

            console.log(`got ${querySnapshot.size} document${querySnapshot.size > 0 ? 's' : ''}`);
            const typesenseDocuments = [];
            querySnapshot.forEach(doc => typesenseDocuments.push(getTypsenseDoc({ id: doc.id, ...doc.data() })));

            if (typesenseDocuments.length > 0) {
                const resp = await client.collections(JOB_COLLECTION).documents().import(typesenseDocuments, { action: 'create' });
                console.log(resp);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const onClickDelete = async () => {
        try {
            const resp = await client.collections(JOB_COLLECTION).delete();
            console.log(resp);
        }
        catch (error) {
            console.error(error);
        }
    }

    const disableButtons = false;

    return (
        <Grid container spacing={2} direction="row" className={classes.container}>
            <Grid item xs={12} sm={6} md={4} lg={3} >
                <BaseButton
                    variant="contained"
                    onClick={onClickDelete}
                    buttonClass="delete"
                    disabled={disableButtons}
                    label={`Delete ${JOB_LABEL} collection`}
                >
                </BaseButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <BaseButton
                    variant="contained"
                    color="primary"
                    onClick={onClickCreate}
                    disabled={disableButtons}
                    label={`Create ${JOB_LABEL} collection`}
                >
                </BaseButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <BaseButton
                    variant="contained"
                    color="secondary"
                    onClick={onClickIndex}
                    disabled={disableButtons}
                    label={`Index ${JOB_LABEL} collection`}
                >
                </BaseButton>
            </Grid>
        </Grid>
    )
}