import React, { useCallback, useContext, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core/";
import Heading from "../../base-components/typography/heading";
import { getBGColorForService } from "../../../utils/";
// import { countryOptions } from "../../../utils/";
// import { Graduation, UserClock, HoursGlass, Car } from "../../components/icons";
import RelatedJobs from "../related";
// import DynamicEditor from "../../components/editor/";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Car from "../../../icons/car";
import Graduation from "../../../icons/graduation";
import HourGlass from "../../../icons/hours-glass";
import UserClock from "../../../icons/user-clock";

import DynamicEditor from "../editor/index";
import SiteLayout from "../../../layout/dashboard-layout/jobs-layout";
import Modal from "./modal";
import { Context } from "../../../utils/context-provider";

import NewButton from "../../../components/base-components/base-button/new-button";
import LoginPage from "../../../screens/login-password-reset";
import LoginModal from "../../../screens/login-password-reset/login-modal";
import Close from "@material-ui/icons/Close";
import { countryOptions } from "../../../utils/constants";
import Typesense from "typesense";
import { searchServerConfig } from "../../../utils/search/typesense-config";
import firebase from "../../../utils/firebase";
import DashboardLayout from "../../../layout/dashboard-layout";
import { Alert } from "@material-ui/lab";
import { applyForJob } from "../../../utils/server-functions";
import { getFormattedDateShort } from "../../../utils/date-format";

const useStyles = makeStyles((theme) => {
  return {
    main: {
      position: "relative",
      marginTop: 70,
      // paddingTop: 30,
      marginBottom: 30,
      fontFamily: "Roboto",

      [theme.breakpoints.down("xs")]: {
        marginTop: 70,
        paddingTop: 0,
      },
    },
    title: {
      marginBottom: "5px !important",
      textTransform: "none !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: 24,
      },
    },
    subTitle: {
      marginBottom: "5px !important",
      textTransform: "none !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: 21,
      },
    },
    span: {
      fontSize: 16,
      lineHeight: "18px",
      fontWeight: 400,
      paddingLeft: 15,
      paddingRight: 15,
      marginBottom: "5px !important",
      fontFamily: "Roboto",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    jobContainer: {
      padding: 30,
      borderWidth: 8,
      borderStyle: "solid",
      borderColor: (p) => getBGColorForService(p.service),
      backgroundColor: theme.palette.secondary.light,
      minHeight: "calc( 100vh - 130px )",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      [theme.breakpoints.down("xs")]: {
        padding: 15,
        alignItems: "flex-start",
        minHeight: "auto",
        "& h1": {
          fontSize: "21px",
        },
      },
    },
    contentContainer: {
      paddingTop: "30px",
      alignItems: "flex-start",
    },
    requiredInfo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: "Roboto",

      "& div": {
        marginRight: "8px",
      },
    },

    close: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      padding: "15px 0",
      width: "50%",
      margin: "auto",
      cursor: "pointer",
      fontWeight: "bold",
      textDecoration: "none",
      fontSize: 14,
      lineHeight: "28px",
      paddingRight: 5,

      color: theme.palette.secondary.dark,

      [theme.breakpoints.down("md")]: {
        paddingLeft: 15,
        width: "70%",
      },

      [theme.breakpoints.down("sm")]: {
        paddingLeft: 15,
        width: "100%",
        justifyContent: "start",
      },
    },

    button: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "150px",
      height: "30px",
      borderRadius: "50px",
      fontWeight: "bold",
      color: "white",
      backgroundColor: `${getBGColorForService("orange")}`,
      cursor: "pointer",
      padding: "20px 20px",
      transition: "all 0.3s ease-in-out",
      border: `solid 4px ${getBGColorForService("orange")}`,
      marginTop: 10,
      marginRight: 20,
      "&:hover": {
        backgroundColor: "white",

        color: `${getBGColorForService("orange")}`,
      },
    },
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      paddingTop: 30,
      width: "100%",
      "&>button": { marginRight: 20 },
      marginBottom: 10,
    },
    loginWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    header: {
      fontSize: 45,
      fontWeight: "bold",
      textAlign: "center",
    },
    required: {
      textAlign: "left",
      width: "100%",
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
    },
    message: {
      marginTop: 20,
      fontSize: 16,
      fontWeight: "400",
    },
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    headerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
    wrapperButtonAlert: {
      padding: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
    },
    meta: {
      fontFamily: "Roboto",

      color: theme.palette.secondary.dark,
      fontSize: 14,
      lineHeight: "21px",
      paddingLeft: 8,
      paddingRight: 8,
      textTransform: "uppercase",
      fontWeight: 600,
    },
  };
});

const typesenseClient = new Typesense.Client(searchServerConfig);
const typesenseCollection = typesenseClient.collections("jobs");

export default function SingleJob() {
  let { jobId } = useParams();
  const [dataJob, setJobData] = useState({});

  const {
    id,
    title,
    companyName,
    type = "full-time",
    description,

    service,

    level,
    minimumExperienceRequired,
    driverLicenseRequired,
    newDescription,
    company,
    companyEmail,
  } = dataJob || {};

  const lowerCase = service?.toLowerCase();
  const classes = useStyles({ service: lowerCase });
  const [showModal, setShowModal] = useState();
  const { user, allJobs } = useContext(Context);
  const [selectedButton, setSelectedButton] = useState(null);
  const [modalId, setIdModal] = useState(1);
  const isFavoriteOrisSelected =
    user?.favoritedJobs?.includes(id) || user?.selectedJobs?.includes(id);

  const country =
    countryOptions.find((i) => i.id === dataJob.country)?.label || "";
  const requiredItems = [];

  const [loading, setLoading] = useState(true);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [appliedDate, setAppliedDate] = useState("");

  const handleApply = (id) => {
    setSelectedButton("apply");
    setShowModal(true);
  };
  const handleAddToCart = () => {
    setSelectedButton("addToCart");
    setShowModal(true);
  };

  const onSingleApply = async () => {
    await applyForJob([id]);
    setShowModal(false);
  };
  const onClose = async () => {
    setShowModal(false);
  };

  const [jobsData, setJobsData] = useState([]);

  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        const searchParameters = {
          query_by: "service",
          q: `${service}`,
          per_page: 6,
          filter_by: `status:!=trash`,
          hidden_hits: `${jobId}`,
        };

        const { hits } = await typesenseCollection
          .documents()
          .search(searchParameters);

        setJobsData(hits);
      } catch (error) {
        console.error("Error fetching jobs data:", error);
      }
    };

    fetchJobsData();
  }, [jobId, lowerCase, service]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await typesenseCollection.documents(jobId).retrieve();
        setJobData(response);
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error("Error fetching company data:", error);
        setLoading(false); // Set loading to false on error as well
      }
    };

    fetchCompanyData();
  }, [jobId]);

  useEffect(() => {
    let checkUserApplied = false;
    let appliedDate = null;

    if (user?.appliedJobs) {
      const job = user.appliedJobs.find((job) => job.id === id);

      if (job) {
        checkUserApplied = true;
        appliedDate = getFormattedDateShort(job.appliedForDate);
      }
    }

    setAlreadyApplied(checkUserApplied);
    setAppliedDate(appliedDate);
  }, [id, user?.appliedJobs]);

  const handleFavorite = async () => {
    const userRef = firebase.firestore().collection("users").doc(user.uid);

    const userDoc = await userRef.get();

    if (userDoc.exists) {
      const userData = userDoc.data();
      const newItem = id; // Change this to the item you want to add/remove

      // Check if the item exists in the favorites array
      if (userData.selectedJobs && userData.selectedJobs.includes(newItem)) {
        // If the item exists in selectedJobs, remove it from selectedJobs
        return userRef.update({
          selectedJobs: firebase.firestore.FieldValue.arrayRemove(newItem),
        });
      } else {
        // If the item doesn't exist in selectedJobs, check and handle favorites
        if (
          userData.favoritedJobs &&
          userData.favoritedJobs.includes(newItem)
        ) {
          // If the item exists in favoritedJobs, remove it from favoritedJobs
          return userRef.update({
            favoritedJobs: firebase.firestore.FieldValue.arrayRemove(newItem),
          });
        } else {
          // If the item doesn't exist in favoritedJobs, add it to favoritedJobs
          return userRef.update({
            favoritedJobs: firebase.firestore.FieldValue.arrayUnion(newItem),
          });
        }
      }
    }

    return null; // Handle the case where the user document doesn't exist
  };

  const renderNoLoginUser = () =>
    //   add !user for testing login modal, still have to replicate this for public site!!
    user ? (
      <div className={classes.loginWrapper}>
        {requiredItems.length > 0 ? (
          <>
            <div className={classes.header}>Requirements</div>
            To apply for this job, need the following requirements
            {requiredItems.map((item, i) => {
              return (
                <div className={classes.required}>
                  <Close style={{ color: "red", marginRight: 10 }} />
                  {item.title}
                </div>
              );
            })}
          </>
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.message}>
              You are about to apply for this job, to proceed click ok
            </div>
            <div>
              <div className={classes.buttonWrapper}>
                <NewButton label={"Ok"} onClick={onSingleApply} />
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className={classes.loginWrapper}>
        <LoginModal isModal={true} onClose={onClose} setIdModal={setIdModal} />
      </div>
    );

  return (
    <DashboardLayout singlePage={true} pageCategory="singlePage">
      {!loading ? (
        <main role="main" className={classes.main}>
          <a className={classes.close} href="/jobs">
            <KeyboardBackspaceIcon className={classes.iconButton} />
            <span>Back</span>
          </a>

          <Grid container justifyContent="center">
            <Grid item xs={11} sm={11} md={8} lg={6}>
              <div className={classes.jobContainer}>
                <div className={classes.headerContainer}>
                  <Heading
                    color="main.primary"
                    variant="h1"
                    className={classes.title}
                  >
                    {title}
                  </Heading>
                  <span className={classes.span}>{companyName}</span>
                  <span className={classes.span}>
                    {(country || "").toUpperCase()}
                  </span>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.contentContainer}
                  >
                    <Grid item xs={12}>
                      <Heading
                        color="main.secondary"
                        variant="h2"
                        className={classes.subTitle}
                      >
                        Required
                      </Heading>
                    </Grid>
                    {level && (
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <div className={classes.requiredInfo}>
                          <Graduation /> {level}
                        </div>
                      </Grid>
                    )}
                    {minimumExperienceRequired && (
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <div className={classes.requiredInfo}>
                          <UserClock />
                          {minimumExperienceRequired}
                        </div>
                      </Grid>
                    )}
                    {type && (
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <div className={classes.requiredInfo}>
                          <HourGlass />{" "}
                          {type === "full-time" ? "Full time" : "Part time"}
                        </div>
                      </Grid>
                    )}
                    {driverLicenseRequired && (
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <div className={classes.requiredInfo}>
                          <Car /> {driverLicenseRequired}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.contentContainer}
                  >
                    <Grid item xs={12}>
                      <DynamicEditor
                        id="newDescription"
                        readOnly={true}
                        description={newDescription || description || undefined}
                      />
                    </Grid>
                  </Grid>
                  {company?.newDescription && (
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.contentContainer}
                    >
                      <Grid item xs={12}>
                        <Heading
                          color="secondary"
                          variant="h2"
                          className={classes.title}
                        >
                          {company?.title}
                        </Heading>
                        <br />
                        <DynamicEditor
                          id="newCompanyDescription"
                          readOnly={true}
                          description={company.newDescription}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
                {!!companyEmail ? (
                  <div className={classes.wrapperButtonAlert}>
                    {!alreadyApplied ? (
                      <div className={classes.buttonWrapper}>
                        <NewButton
                          className={classes.button}
                          onClick={handleApply}
                          disabled={alreadyApplied ? "" : false}
                          label={"Apply Now"}
                        />
                        {/* <NewButton
                    className={classes.button}
                     onClick={handleFavourite}
                    label={"Add to cart"}
                  /> */}

                        {!isFavoriteOrisSelected ? (
                          <NewButton
                            className={classes.button}
                            onClick={handleFavorite}
                            label={"Add to Favorites"}
                            disabled={alreadyApplied ? "" : false}
                          />
                        ) : (
                          <NewButton
                            className={classes.button}
                            onClick={handleFavorite}
                            label={"Remove from Favorites"}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <Alert>Applied on {appliedDate} </Alert>
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <a className={classes.close} href="/jobs">
            <KeyboardBackspaceIcon className={classes.iconButton} />
            <span>Back</span>
          </a>
          <RelatedJobs jobs={jobsData} service={service} id={id} />
          {showModal && (
            <Modal onClose={onClose} modalId={modalId}>
              {selectedButton === "apply" ? (
                renderNoLoginUser()
              ) : (
                <div>
                  <h3>Add to jobs cart</h3>
                  {/* Additional form or content for adding to cart */}
                </div>
              )}
            </Modal>
          )}
        </main>
      ) : null}
    </DashboardLayout>
  );
}
