import {
    Grid, makeStyles,
} from '@material-ui/core';
import Heading from '../base-components/typography/heading';

const extractEmail = (str) => {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    const matches = (str && str.match) ? str.match(emailRegex) : null;
    return matches ? matches[0].toLowerCase() : null;
};


function saveArrayToFile(array, filename) {
    const data = array.join('\n'); // Concatenate array elements with line breaks

    const blob = new Blob([data], { type: 'text/plain' });

    if (window.navigator.msSaveBlob) {
        // For IE and Edge browsers
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // For other browsers
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
}

const useStyles = makeStyles(() => ({
    container: {
        marginTop: 100,
        marginLeft: 30,
    }
}));

export default function DashboardJobseeker() {
    const classes = useStyles();

    return (
        <Grid container spacing={1} direction="column">
            <Grid item xs={12} container justifyContent="space-between">
                <Grid container spacing={2} direction="row" className={classes.container}>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <Heading color="secondary.dark" variant="h1">My Dashboard</Heading>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}