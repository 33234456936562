import
React,
{ createRef, useEffect } from 'react';
import {
    makeStyles,
    Grid,
} from '@material-ui/core';
// import AddJobForm from '../../components/jobs/add-job-index';
import logoWhite from '../../../assets/svg/logo_white.svg';
import { getColorForService, renderOGImageCanvas } from '../../../utils';
import { ogCanvasMeasurements } from '../../../utils/constants';
import AddOrUpdateJobForm from './add-or-update-job-form';
import Heading from '../../base-components/typography/heading';

const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100%'
    },
    mainPadding: {
        padding: '120px 60px 60px 60px'
    },
    container: {
        backgroundColor: '#CCCCCC',
        padding: '30px',
    },
    title: {
        'font-size': '40px',
        'line-height': '24px',
        'color': '#FFFFFF',
        'font-weight': '400',
        'margin-bottom': '30px',
        'margin-top': '0',
    },
    subTitle: {
        'font-size': '16px',
        'line-height': '19px',
        'color': '#FFFFFF',
        'font-weight': '500',
        'margin-bottom': '30px',
        'margin-top': '0',
    },

    seoImage: {
        'border': '1px solid #d3d3d3',
    },

    logoImage: {
        'display': 'none',
    },
    floatingPreview: {
        position: 'sticky',
        top: '90px',
    },
}));


export default function AddOrUpdateJob() {
    const classes = useStyles();
    const canvasPreview = createRef();
    const logoImgRef = createRef();
    const { preview, font } = ogCanvasMeasurements;
    
    useEffect(() => {
        const { width, height, logo } = preview;
        if (canvasPreview.current) {
            const context = canvasPreview.current.getContext('2d');
            context.fillStyle = getColorForService();
            context.fillRect(0, 0, width, height);
            const image = logoImgRef.current;
            context.drawImage(image, (width / 2) - (logo.width / 2), logo.yPos, logo.width, logo.height);
        }
    }, [canvasPreview, logoImgRef, preview]);

    const onUpdatePreview = jobData => {
        if (canvasPreview.current) {
            const context = canvasPreview.current.getContext('2d');
            const image = logoImgRef.current;
            renderOGImageCanvas({ context, image, font, ...preview, ...jobData });
        }
    }

    return (
        <Grid container direction="row" alignItems="strech" className={classes.mainContainer}>
            <Grid item xs={12} sm={12} md={12} lg={9}>
                <div className={classes.mainPadding}>
                    <AddOrUpdateJobForm updatePreview={onUpdatePreview} />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} className={classes.container}>
                <div className={classes.floatingPreview}>
                    <Heading color="secondary.dark" variant="h5">Job Listings</Heading>
                    <img className={classes.logoImage} ref={logoImgRef} src={logoWhite || ''} alt="Mi kier traha logo" />
                    <canvas className={classes.seoImage} ref={canvasPreview} height={preview.height} width={preview.width}></canvas>
                </div>
            </Grid>
        </Grid>
    );
}