import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import {
    makeStyles,
    Grid,
    Box
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import CompaniesActions from './companies-actions';
import BaseButton from '../base-components/base-button';
import { companyDataConverter } from '../../utils/data-converters';
import { useCollectionData } from '../../utils/firebase';
import Heading from '../base-components/typography/heading';
import Table from '../table';
// import PromptDialog from '../promp-dialog';
import Preloader from '../preloader/';
import { COMPANIES_COLLECTION } from '../../utils/constants';

const useStyles = makeStyles(() => ({
    mainPadding: {
        padding: '90px 15px 15px 15px'
    },
    mt: {
        marginTop: '15px'
    },
}));

export default function CompaniesOverview() {
    const classes = useStyles();
    const navigate = useNavigate();
    const query = useCallback(ref => ref.where('status', '!=', 'trash'), []);
    const [data, loading, error] = useCollectionData(COMPANIES_COLLECTION, companyDataConverter, query);

    if (loading) {
        return (
            <div>
                <Preloader height="100vh" />
            </div>
        )
    }
    if (error)
        return <p>Something went wrong, please try again later</p>

    const companiesTableHead = [
        // { headerName: 'ID', fields: ['id'] },
        { headerName: 'Title', fields: ['title'] },
        { headerName: 'Contact Email', fields: ['email'] },
        // { headerName: 'Actions', fields: ['actions'] },
    ];
    return (
        <Grid container direction="column" className={classes.mainPadding}>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item xs={12} sm={12} md={12} lg={9}>
                    <Heading color="secondary.dark" variant="h3">Company listings</Heading>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Box display="flex" justifyContent="flex-end">
                        <BaseButton onClick={() => navigate('/admin/companies/new')} color="primary" label="Add New Company" startIcon={<AddCircle />} />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.mt}>
                <Grid item xs={12}>
                    <Table
                        data={data}
                        type={'companies'}
                        columns={[
                            ...companiesTableHead,
                            {
                                headerName: 'Actions',
                                fieldType: 'actionsComponent',
                                component: CompaniesActions
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}