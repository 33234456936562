import firebase from '../../utils/firebase';
import {
    jobDataConverter,
    companyDataConverter,
    adDataConverter,
    userAccountDataConverter,
} from '../../utils/data-converters';

const dataConverters = {
    'jobs': jobDataConverter,
    'companies': companyDataConverter,
    'ads': adDataConverter,
    'users': userAccountDataConverter,
}

export default function ImportExport() {

    const importCollection = async (collectionName) => {

        if (collectionName) {
            let ref = firebase
                .firestore()
                .collection(collectionName);
            if (dataConverters[collectionName]) {
                ref = ref.withConverter(dataConverters[collectionName])
                    // .where('status', '!=', 'trash')
                    // .orderBy('status', 'asc')
                    // .orderBy('updated', 'desc')
                    ;
            }
            const snapshot = await ref.get();
            // const timestamp = getFormattedDate(new Date().getTime(), true);
            // console.log(`Got ${snapshot.size} ${collectionName} ${timestamp}`);

            const collectionData = [];
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    collectionData.push({ ...doc.data(), id: doc.id });
                }
            });

            let collectionRef = await firebase
                .firestore()
                .collection(`_${collectionName}`);
            if (dataConverters[collectionName]) {
                collectionRef = collectionRef.withConverter(dataConverters[collectionName])
            }

            let batch = firebase.firestore().batch();
            for (let i = 0; i < collectionData.length; i++) {
                const { id, ...data } = collectionData[i];
                const docRef = collectionRef.doc(id);
                batch.set(docRef, { ...data });
                if (i > 0 && i % 499 === 0) {
                    try {
                        console.log('committing...');
                        await batch.commit();
                        batch = firebase.firestore().batch();
                        console.log('committed');
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
            try {
                console.log('committing...');
                await batch.commit();
                batch = firebase.firestore().batch();
                console.log('committed');
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <button style={{ margin: '150px' }} onClick={() => importCollection('users')}>Test</button>
    )
}