import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    CircularProgress,
    Grid,
    makeStyles
} from "@material-ui/core";
import { Tooltip, } from '@material-ui/core/';
import {
    Edit,
    Delete,
} from '@material-ui/icons';
import CheckboxField from "../base-components/form-fields/checkbox-field";
import PromptDialog from '../promp-dialog';
import firebase from '../../utils/firebase';

const useStyles = makeStyles(() => ({
    link: {
        marginRight: 10,
        lineHeight: '14px',
    },
    actionContainer: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
}));

export default function CompaniesActions({ dataFields, type }) {

    const {
        id,
        isSubscriber,
    } = dataFields || {};

    const classes = useStyles();
    const [selected, setSelected] = useState(null);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const update = async () => {
            const { id, value } = selected || {};
            if (value !== dataFields[id]) {
                setLoading(true);
                try {
                    await firebase
                        .firestore()
                        .collection(type)
                        .doc(dataFields.id)
                        .update({ [id]: value });
                }
                catch (e) {
                    console.error(e)
                }
                setLoading(false);
            }
        }

        update();

    }, [selected])

    const onConfirmDelete = id => {
        setShowDeletePrompt(true);
        setSelected({ id });
    }

    const onClickConfirmDelete = async (e) => {
        setShowDeletePrompt(false);
        await firebase
            .firestore()
            .collection(type)
            .doc(selected)
            .update({ status: 'trash' });
    }

    const onHandleClose = () => {
        setShowDeletePrompt(false);
    };

    const onChange = async ({ target }) => {
        const { id, value } = target || {};
        setSelected({ id, value });
    }

    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.actionContainer}>
                <Link to={`/admin/${type}/${id}/edit`} className={classes.link}>
                    <Tooltip title={`Edit`} aria-label="edit" placement="top" arrow>
                        <Edit color="primary" fontSize="small" />
                    </Tooltip>
                </Link>
                <Link to="#" onClick={() => onConfirmDelete(id)} className={classes.link}>
                    <Tooltip title={`Delete`} aria-label="delete" placement="top" arrow>
                        <Delete color="error" fontSize="small" />
                    </Tooltip>
                </Link>
                <CheckboxField
                    id="isSubscriber"
                    name="isSubscriber"
                    label="Subscriber"
                    value={isSubscriber || false}
                    onChange={onChange}
                />
                {loading && <CircularProgress />}
            </Grid>
            {
                showDeletePrompt &&
                <PromptDialog
                    open={showDeletePrompt}
                    onHandleClose={onHandleClose}
                    onClickConfirm={onClickConfirmDelete}
                    title={`Delete item?`}
                />
            }
        </>
    )
}