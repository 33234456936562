import { useState, createRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    makeStyles,
    Grid,
} from '@material-ui/core';
import { Drafts, FileCopy, Publish } from '@material-ui/icons';
// import DeleteIcon from '@material-ui/icons/Delete';
// import Breadcrumb from '../breadcrumb';
import Heading from '../../base-components/typography/heading';
import {
    FormGroup,
    FormControlLabel
} from '@material-ui/core';
import {
    TextField,
    // SelectField,
    AutoCompleteField,
    RadioField,
    DatePickerField,
    DateTimePickerField,
    CheckboxField,
    ChipSelectField,
} from '../../base-components/form-fields';
import { renderOGImageCanvas, urlify } from '../../../utils';
import BaseButton from '../../base-components/base-button';
import firebase, { useCollectionData, useDocumentData } from '../../../utils/firebase';
import { jobDataConverter } from '../../../utils/data-converters';
import {
    serviceOptions,
    jobLevelOptions,
    minimumExperienceRequiredOptions,
    typeOptions,
    countryOptions,
    ogCanvasMeasurements,
    BRANCHES_COLLECTION,
    COMPANIES_COLLECTION,
    JOBS_COLLECTION,
} from '../../../utils/constants';
// import PromptDialog from '../../promp-dialog';
// import { companyLabelRenderer } from '../../../utils/label-format';
import logoWhite from '../../../assets/svg/logo_white.svg';
import Preloader from '../../preloader/';
import Editor from '../../editorjs/';

const MAX_TITLE_CHARS = 15;
const MAX_COMPANY_CHARS = 25;

const useStyles = makeStyles((theme) => ({
    container: {
        paddingRight: 30,
        flexDirection: 'column',
    },
    floatingBox: {
        backgroundColor: '#FFFFFF',
        padding: 30,
        boxShadow: '-7px 7px 20px #00000029',
        width: '100%',
        position: 'sticky',
        top: 110,
        height: 375,
        [theme.breakpoints.down('lg')]: {
            height: 425
        },
    },
    buttons: {
        width: '100%',
    },
    horizontal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    label: {
        fontSize: '0.75em',
        color: 'rgba(0, 0, 0, 0.87)',
        textTransform: 'uppercase',
    },
    pdngLeft: {
        paddingLeft: 5,
    }
}));

const saveToDatabase = async (jobId, jobData) => {
    await firebase.firestore()
        .collection(JOBS_COLLECTION)
        .doc(jobId)
        .withConverter(jobDataConverter)
        .set({ ...jobData, userChange: true }, { merge: true })
}

export default function AddOrUpdateJobForm({ updatePreview, ...props }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    let { jobId } = useParams();
    const { dataProps } = location?.state || {};
    const logoImgRef = createRef();
    const [saving, setSaving] = useState(null);
    const [titleChanged, setTitleChanged] = useState(false);
    const [companyNameChanged, setCompanyNameChanged] = useState(false);
    const [externalLinkChanged, setExternalLinkChanged] = useState(false);
    const [hasErrors] = useState(false);
    // const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [data, loading, error, onDocChange] = useDocumentData(JOBS_COLLECTION, jobId, jobDataConverter, dataProps ? { ...dataProps, externalLink: '' } : null);
    const [companies, companiesLoading, companiesError] = useCollectionData(COMPANIES_COLLECTION);
    const [branches] = useCollectionData(BRANCHES_COLLECTION);
    const [dataChanged, setDataChanged] = useState(false);
    const [companyEmail, setCompanyEmail] = useState(undefined);

    if (!companyEmail && data?.companyEmail && companyEmail !== data?.companyEmail) {
        setCompanyEmail(data?.companyEmail)
    }

    const onChange = e => {
        setDataChanged(true);
        onDocChange(e);
    }

    const save = async (status) => {
        setSaving(status);
        const timestamp = new Date().getTime();

        const { original, seo, font } = ogCanvasMeasurements;
        let canvas = document.createElement('canvas');
        canvas.width = original.width;
        canvas.height = original.height;
        let context = canvas.getContext('2d');
        const image = logoImgRef.current;

        renderOGImageCanvas({ context, image, font, ...original, ...data });
        const seoImageDataUrl = canvas.toDataURL();
        const seoImageData = seoImageDataUrl.split(',')[1];

        canvas = document.createElement('canvas');
        canvas.width = seo.width;
        canvas.height = seo.height;
        context = canvas.getContext('2d');
        renderOGImageCanvas({ context, image, font, ...seo, ...data });

        const seoImage = canvas.toDataURL();

        try {
            if (!hasErrors) {
                const {
                    id,
                    ...job
                } = data;
                if (!jobId) {
                    const linkId = data.externalLink.replace('https://mikiertraha.com/job/', '');
                    const doc = await firebase.firestore().collection(JOBS_COLLECTION).doc(linkId).get();
                    if (!doc.exists) {
                        jobId = linkId;

                        const storagePath = `/jobs/${jobId}/seo-image.png`;
                        const storageRef = firebase.storage().ref(storagePath);
                        await storageRef.putString(seoImageData, 'base64', { contentType: 'image/png' });
                        const seoImageUrl = await storageRef.getDownloadURL();
                        // const seoImageUrl = `https://storage.googleapis.com/mi-kier-traha.appspot.com/jobs/${jobId}/seo-image.png`;

                        const jobData = {
                            ...job,
                            status,
                            seoImage,
                            seoImageUrl,
                            companyEmail: companyEmail || company?.email || null,
                            created: timestamp,
                            updated: timestamp
                        };
                        await saveToDatabase(jobId, jobData);
                        navigate(`/admin/jobs/${jobId}/edit`);
                    } else {
                        alert('Doc already exists');
                    }
                }
                else {

                    // TODO: remove duplicate
                    const storagePath = `/jobs/${jobId}/seo-image.png`;
                    const storageRef = firebase.storage().ref(storagePath);
                    await storageRef.putString(seoImageData, 'base64', { contentType: 'image/png' });
                    const seoImageUrl = await storageRef.getDownloadURL();
                    // const seoImageUrl = `https://storage.googleapis.com/mi-kier-traha.appspot.com/jobs/${jobId}/seo-image.png`;

                    const jobData = {
                        ...job,
                        status,
                        seoImage,
                        seoImageUrl,
                        companyEmail: companyEmail || company?.email || null,
                        updated: timestamp
                    };
                    setDataChanged(false);
                    await saveToDatabase(jobId, jobData);
                }
            }
        } catch (error) {
            console.log(error)
        }
        setSaving(null);
    }

    if (loading || companiesLoading) {
        return <Preloader />
    }
    if (error || companiesError) {
        return <p>Something went wrong, please try again later</p>
    }

    const company = companies.find(({ id }) => id === data.companyID);
    data.companyName = company?.label || company?.title || '';
    if (!externalLinkChanged) {
        data.externalLink = `https://mikiertraha.com/job/${data.companyID || 'company-id'}-${urlify(data.title || '')}`;
    }
    if (!titleChanged && !jobId) {
        data.titleLine1 = data?.title?.substr(0, MAX_TITLE_CHARS);
        if (data.title?.length >= MAX_TITLE_CHARS) {
            data.titleLine2 = data.title.substr(MAX_TITLE_CHARS, MAX_TITLE_CHARS);
        } else {
            data.titleLine2 = '';
        }
    }
    if (!companyNameChanged && !jobId) {
        data.companyNameLine1 = data.companyName.substr(0, MAX_COMPANY_CHARS);
        if (data.companyName?.length >= MAX_COMPANY_CHARS) {
            data.companyNameLine2 = data.companyName.substr(MAX_COMPANY_CHARS, MAX_COMPANY_CHARS);
        } else {
            data.companyNameLine2 = '';
        }
    }

    const driversLicense = data.driversLicense || Array(5).fill(false);

    const onDriversLicenseChange = (index, value) => {
        let arr = [...driversLicense];
        arr[index] = value;
        onChange({ target: { id: 'driversLicense', value: arr } })
    }

    updatePreview(data);

    const companiesOptions = companies.map(company => {
        return (
            {
                id: company.id,
                label: company.label || company.title
            }
        )
    });

    const labelText = jobId && data?.status === 'published' ? 'Save' : 'Publish';
    const labelTextSaving = jobId && data?.status === 'published' ? 'Saving...' : 'Publishing...';

    data.country = data.country ? data.country : 'aua';

    return (
        <>
            <Grid container direction="column">
                <Grid item xs={12} container>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Heading color="secondary.dark" variant="h1">{`${jobId ? 'UPDATE' : 'NEW'} JOB`}</Heading>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Grid xs={12} container direction="row" justifyContent='flex-end'>
                            <Grid item xs={12} sm={12} md={12} lg={4} className={classes.pdngLeft}>
                                <BaseButton
                                    label={saving === 'published' ? labelTextSaving : labelText}
                                    onClick={() => save('published')}
                                    color="primary"
                                    endIcon={<Publish />}
                                    className={classes.buttons}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} className={classes.pdngLeft}>
                                <BaseButton
                                    label={saving === 'draft' ? 'Saving...' : 'Save as draft'}
                                    onClick={() => save('draft')}
                                    color="secondary"
                                    endIcon={<Drafts />}
                                    className={classes.buttons}
                                />
                            </Grid>
                            {
                                jobId &&
                                <Grid item xs={12} sm={12} md={12} lg={4} className={classes.pdngLeft}>
                                    <BaseButton
                                        label={'Duplicate'}
                                        onClick={() => navigate('/admin/jobs/new', { dataProps: data })}
                                        color="secondary"
                                        endIcon={<FileCopy />}
                                        className={classes.buttons}
                                        disabled={dataChanged === true}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="space-between">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container direction="column">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Heading color="secondary.dark" variant="h6">Job Description</Heading>
                            </Grid>
                            <Grid container spacing={2} direction="row">
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <TextField
                                        id="title"
                                        name="title"
                                        label="Title"
                                        value={data.title || ''}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <AutoCompleteField
                                        id="service"
                                        name="service"
                                        label="Service"
                                        value={data.service || ''}
                                        options={serviceOptions}
                                        onChange={onChange}
                                        validation={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <div className={classes.label}>Counrty</div>
                                    <RadioField
                                        id="country"
                                        name="country"
                                        label="Country"
                                        value={data.country || ''}
                                        options={countryOptions}
                                        onChange={onChange}
                                        validation={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <DateTimePickerField
                                        id="publishDate"
                                        label="Publish Date"
                                        name="publishDate"
                                        value={data.publishDate || null}
                                        onChange={onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <DatePickerField
                                        id="unpublishDate"
                                        label="Unpublish Date"
                                        name="unpublishDate"
                                        value={data.unpublishDate || null}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <AutoCompleteField
                                        id="companyID"
                                        name="companyID"
                                        label="Company Name"
                                        value={data?.companyID || ''}
                                        options={companiesOptions}
                                        onChange={onChange}
                                    // required={true}
                                    // hasError={fieldsWithError['companyID']}
                                    // onError={setFieldError}
                                    // errorMessage="Must select company"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <TextField
                                        id="companyEmail"
                                        name="companyEmail"
                                        label="Company email"
                                        value={companyEmail || company?.email || ''}
                                        onChange={e => setCompanyEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="externalLink"
                                        name="externalLink"
                                        label="External Link"
                                        value={data.externalLink || ''}
                                        disabled={jobId}
                                        onChange={e => {
                                            setExternalLinkChanged(true);
                                            onChange(e);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Heading color="secondary.dark" variant="h6">Preview Social</Heading>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <TextField
                                        id="titleLine1"
                                        name="titleLine1"
                                        label="Title Top Line"
                                        value={data.titleLine1 || ''}
                                        onChange={e => {
                                            setTitleChanged(true);
                                            onChange(e);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <TextField
                                        id="titleLine2"
                                        name="titleLine2"
                                        label="Title Bottom Line"
                                        value={data.titleLine2 || ''}
                                        onChange={e => {
                                            setTitleChanged(true);
                                            onChange(e);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <TextField
                                        id="companyNameLine1"
                                        name="companyNameLine1"
                                        label="Company Top Line"
                                        value={data.companyNameLine1 || ''}
                                        onChange={e => {
                                            setCompanyNameChanged(true);
                                            onChange(e);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <TextField
                                        id="companyNameLine2"
                                        name="companyNameLine2"
                                        label="Company Bottom Line"
                                        value={data.companyNameLine2 || ''}
                                        onChange={e => {
                                            setCompanyNameChanged(true);
                                            onChange(e);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Heading color="secondary.dark" variant="h6">Additional Information</Heading>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <AutoCompleteField
                                        id="level"
                                        name="level"
                                        label="Level"
                                        value={data.level || ''}
                                        options={jobLevelOptions}
                                        onChange={onChange}
                                        validation={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <AutoCompleteField
                                        id="minimumExperienceRequired"
                                        name="minimumExperienceRequired"
                                        label="Minimum Experience"
                                        value={data.minimumExperienceRequired || ''}
                                        options={minimumExperienceRequiredOptions}
                                        onChange={onChange}
                                        validation={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <div className={classes.label}>Type</div>
                                    <RadioField
                                        id="type"
                                        name="type"
                                        label="Type"
                                        value={data.type || ''}
                                        options={typeOptions}
                                        onChange={onChange}
                                        validation={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Grid container direction="row">
                                        {/* <Grid item xs={12} sm={12} md={12} lg={4}>
                                            <CheckboxField
                                                id="driversLicenseRequired"
                                                name="driversLicenseRequired"
                                                label="Driver's License"
                                                value={data.driversLicenseRequired || ''}
                                                onChange={onChange}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div className={classes.label}>Drivers License</div>
                                            {/* {data.driversLicenseRequired && */}
                                            <FormGroup className={classes.horizontal}>
                                                <FormControlLabel
                                                    control={<CheckboxField
                                                        id="driversLicenseA"
                                                        name="driversLicenseA"
                                                        value={driversLicense[0] || false}
                                                        onChange={e => onDriversLicenseChange(0, e.target.value)}
                                                        label="A"
                                                    />}
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    control={<CheckboxField
                                                        id="driversLicenseB"
                                                        name="driversLicenseB"
                                                        value={driversLicense[1] || false}
                                                        onChange={e => onDriversLicenseChange(1, e.target.value)}
                                                        label="B"
                                                    />}
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    control={<CheckboxField
                                                        id="driversLicenseC"
                                                        name="driversLicenseC"
                                                        value={driversLicense[2] || false}
                                                        onChange={e => onDriversLicenseChange(2, e.target.value)}
                                                        label="C"
                                                    />}
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    control={<CheckboxField
                                                        id="driversLicenseD"
                                                        name="driversLicenseD"
                                                        value={driversLicense[3] || false}
                                                        onChange={e => onDriversLicenseChange(3, e.target.value)}
                                                        label="D"
                                                    />}
                                                    labelPlacement="end"
                                                />
                                                <FormControlLabel
                                                    control={<CheckboxField
                                                        id="driversLicenseE"
                                                        name="driversLicenseE"
                                                        value={driversLicense[4] || false}
                                                        onChange={e => onDriversLicenseChange(4, e.target.value)}
                                                        label="E"
                                                    />}
                                                    labelPlacement="end"
                                                />
                                            </FormGroup>
                                            {/* } */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {/* <SelectField
                                        id="branches"
                                        name="branches"
                                        label="Branches"
                                        value={data.branches || []}
                                        options={branches}
                                        onChange={onChange}
                                        multiple={true}
                                        type="chip"
                                    /> */}
                                    <ChipSelectField
                                        id="branches"
                                        name="branches"
                                        label="Branches"
                                        value={data.branches || []}
                                        options={branches}
                                        optionsId={BRANCHES_COLLECTION}
                                        onChange={onChange}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="description"
                                        name="description"
                                        label="Description"
                                        multiline={true}
                                        value={data?.description}
                                        onChange={onChange}
                                    />
                                </Grid> */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Editor
                                        id="newDescription"
                                        name="newDescription"
                                        label="Description"
                                        value={data?.newDescription || undefined}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} />
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <BaseButton
                            label={saving === 'published' ? labelTextSaving : labelText}
                            onClick={() => save('published')}
                            color="primary"
                            endIcon={<Publish />}
                            className={classes.buttons}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <BaseButton
                            label={saving === 'draft' ? 'Saving...' : 'Save as draft'}
                            onClick={() => save('draft')}
                            color="secondary"
                            endIcon={<Drafts />}
                            className={classes.buttons}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {
                // showDeletePrompt &&
                // <PromptDialog
                // open={showDeletePrompt}
                // onHandleClose={onHandleClose}
                // onClickConfirm={onClickConfirmDelete}
                // title={`Delete job?`}
                // />
            }
            <img style={{ display: 'none' }} ref={logoImgRef} src={logoWhite} alt="Mi kier come logo" />
        </>
    )
}