import {
    makeStyles,
} from '@material-ui/core';
import { Pagination } from "react-instantsearch-dom";

const useStyles = makeStyles((theme) => ({
    pagination: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '& ul': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

            '& li': {
                marginRight: '8px',
                color: theme.palette.secondary.main,
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '25px',
                width: '25px',
                borderRadius: '0',
                fontSize: '12px',
                borderRadius: '50%',
                fontFamily: 'Roboto',

                '& span': {
                    color: theme.palette.secondary.light,
                    fontWeight: 'bold',
                },
                '& a': {
                    color: theme.palette.secondary.light,
                    textDecoration: 'none',
                    backgroundColor: theme.palette.secondary.main,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '25px',
                    width: '25px',
                    borderRadius: '0',
                    fontSize: '12px',
                    borderRadius: '50%',
                    fontFamily: 'Roboto',
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                    }
                },
                '&.ais-Pagination-item--disabled': {
                    display: 'none'
                },
                '&.ais-Pagination-item--selected': {
                    '& a': {
                        backgroundColor: theme.palette.primary.main,
                    }
                },
                '&.ais-Pagination-item--nextPage, &.ais-Pagination-item--lastPage, &.ais-Pagination-item--firstPage, &.ais-Pagination-item--previousPage': {
                    '& a': {
                        color: theme.palette.secondary.main,
                        textDecoration: 'none',
                        backgroundColor: 'transparent',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: 21,
                        height: '25px',
                        width: 'auto',
                        '&:hover': {
                            color: theme.palette.primary.main
                        }
                    },
                }
            }
        }
    }
}));

export default function TypesensePaging({ ...props }) {
    const classes = useStyles();

    return (
        <Pagination
            className={classes.pagination}
            {...props}
        />
    )
}