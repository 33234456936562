import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    makeStyles,
    Grid,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Heading from '../base-components/typography/heading';
import TextField from '../base-components/form-fields/text-field';
import firebase, { useDocumentData } from '../../utils/firebase';
import { companyDataConverter } from '../../utils/data-converters';
import BaseButton from '../base-components/base-button';
// import PromptDialog from '../promp-dialog';
import { urlify } from '../../utils';
import Preloader from '../preloader/';
import Editor from '../editorjs/';
import { COMPANIES_COLLECTION } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: '100%'
    },
    mainPadding: {
        padding: '120px 60px 60px 60px'
    },
    container: {
        paddingRight: 30,
        flexDirection: 'column',
    },
    floatingBox: {
        backgroundColor: '#FFFFFF',
        padding: 30,
        boxShadow: '-7px 7px 20px #00000029',
        width: '100%',
        position: 'sticky',
        top: 110,
        height: 375,
        [theme.breakpoints.down('lg')]: {
            height: 425
        },
    },
    buttons: {
        width: '100%',
    }
}));

export default function AddOrUpdateCompany() {
    const classes = useStyles();
    const { companyId } = useParams();
    const navigate = useNavigate();
    const [data, loading, error, onChange] = useDocumentData(COMPANIES_COLLECTION, companyId, companyDataConverter);
    const [saving, setSaving] = useState(false);
    const [hasErrors] = useState(false);

    const save = async () => {
        setSaving(true);
        if (!hasErrors) {
            const timestamp = new Date().getTime();
            const companyID = urlify(data.title);
            data.externalLink = `https://mikiertraha.com/company/${companyID}`;
            const {
                id,
                ...company
            } = data;
            await firebase
                .firestore()
                .collection(COMPANIES_COLLECTION)
                .withConverter(companyDataConverter)
                .doc(companyID)
                .set({
                    ...company,
                    updated: timestamp,
                    created: company?.created || timestamp
                }, {
                    merge: true
                });
            navigate(`/admin/companies/${companyID}/edit`)
        }
        setSaving(false);
    }


    if (loading) {
        return <Preloader />
    }
    if (error) {
        return <p>Something went wrong, please try again later</p>
    }

    const labelText = companyId ? 'Save' : 'Publish';
    const labelTextSaving = companyId ? 'Saving...' : 'Publishing...';

    return (
        <>
            <Grid container direction="column" className={classes.mainContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.mainPadding}>
                        <Grid container direction="column">
                            <Grid item xs={12} container>
                                <Grid item xs={12} sm={12} md={12} lg={9}>
                                    <Heading color="secondary.dark" variant="h1">{`${companyId ? 'UPDATE' : 'NEW'} COMPANY`}</Heading>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3}>
                                    <BaseButton
                                        label={saving ? labelTextSaving : labelText}
                                        onClick={save}
                                        color="primary"
                                        endIcon={<CloudUploadIcon />}
                                        className={classes.buttons}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container justifyContent="space-between">
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Heading color="secondary.dark" variant="h6">Company Details</Heading>
                                </Grid>
                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="title"
                                            name="title"
                                            label="Title"
                                            value={data.title || data.label || ''}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="email"
                                            value={data.email || undefined}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                    {/*
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                id="description"
                                                name="description"
                                                label="Description"
                                                multiline={true}
                                                value={data?.description}
                                                onChange={onChange}
                                            />
                                        </Grid> 
                                    */}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Editor
                                            id="newDescription"
                                            name="newDescription"
                                            label="Description"
                                            value={data?.newDescription || undefined}
                                            onChange={onChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} spacing={2}>
                                    <BaseButton
                                        label={saving ? labelTextSaving : labelText}
                                        onClick={save}
                                        color="primary"
                                        endIcon={<CloudUploadIcon />}
                                        className={classes.buttons}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            {
                // showDeletePrompt &&
                // <PromptDialog
                // open={showDeletePrompt}
                // onHandleClose={onHandleClose}
                // onClickConfirm={onClickConfirmDelete}
                // title={`Delete company?`}
                // />
            }
        </>
    )
}