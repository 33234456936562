import React from 'react';
import {
    makeStyles,
} from '@material-ui/core';
import {
    EditorContainer,
    Editor,
    InlineToggleButton,
    EditorToolbar,
    ToggleButtonGroup,
    BlockTypeToggleButton
} from 'draft-js-wysiwyg';
import { 
    EditorState, 
    convertToRaw, 
    convertFromRaw,
} from 'draft-js';
import {
    FormatBold as FormatBoldIcon,
    FormatItalic as FormatItalicIcon,
    FormatUnderlined as FormatUnderlinedIcon,
    List as FormatListIcon,
} from '@material-ui/icons';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import 'draft-js/dist/Draft.css';

const useStyles = makeStyles((theme) => ({
    editorToolbar: {
        width:'100%',
        borderLeft: `1px solid #C4C4C4`,
        borderRight: `1px solid #C4C4C4`,
        borderTop: `1px solid #C4C4C4`,
    },
    editorHolder: {
        border: `1px solid #C4C4C4`,
        backgroundColor: theme.palette.secondary.light,
        padding:15,
        minHeight:250,
        marginBottom:30,
    },
    button: {
        border:0
    },
    label: {
        fontSize:'0.75em',
        color: 'rgba(0, 0, 0, 0.87)',
        textTransform: 'uppercase',
        marginBottom:15,
    }
}));

export default function EditorWYSIWYG({
    id,
    label,
    value,
    onChange,
}) {
    const classes = useStyles();
    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty()
    );

    const onEditorChange = (editorState) => {
        setEditorState(editorState);

        const data = editorState.getCurrentContent();
        const content = JSON.stringify(convertToRaw(data));
        
        onChange({ target: { id: 'newDescription', value: content } });
    }   
    
    const editor = React.useRef(null);

    React.useEffect(() => {
        if(value) {
            const contentState = convertFromRaw(JSON.parse(value));

            let newContentState = EditorState.push(
                editorState,
                contentState,
                "remove-range"
            );
            setEditorState(newContentState);
        }
    }, []);

    return (
        <>
            <div className={classes.label}>{label}</div>
            <EditorContainer
                editorState={editorState}
                onChange={onEditorChange}
            >
                <EditorToolbar className={classes.editorToolbar}>
                    <ToggleButtonGroup size='small'>
                        {[
                            ['header-two', 'H2'],
                            ['header-three', 'H3'],
                            ['header-four', 'H4'],
                            ['header-five', 'H5'],
                            ['header-six', 'H6'],
                        ].map(block =>
                            <BlockTypeToggleButton
                                key={`heading-block-${block[0]}`}
                                value={block[0]}
                                className={classes.button}
                            >
                                {block[1]}
                            </BlockTypeToggleButton>
                        )}
                    </ToggleButtonGroup>
                    <ToggleButtonGroup size='small'>
                        {[
                            ['unordered-list-item', <FormatListIcon />],
                            ['ordered-list-item', <FormatListNumberedIcon />],
                        ].map(block =>
                            <BlockTypeToggleButton
                                key={`list-block-${block[0]}`}
                                value={block[0]}
                                className={classes.button}
                            >
                                {block[1]}
                            </BlockTypeToggleButton>
                        )}
                    </ToggleButtonGroup>
                    <ToggleButtonGroup size="small">
                        <InlineToggleButton value="BOLD" className={classes.button}>
                            <FormatBoldIcon />
                        </InlineToggleButton>
                        <InlineToggleButton value="UNDERLINE" className={classes.button}>
                            <FormatUnderlinedIcon />
                        </InlineToggleButton>
                        <InlineToggleButton value="ITALIC" className={classes.button}>
                            <FormatItalicIcon />
                        </InlineToggleButton>
                    </ToggleButtonGroup>
                </EditorToolbar>
                <Editor 
                    id={id} 
                    editorState={editorState} 
                    className={classes.editorHolder} 
                    ref={editor} 
                    placeholder="Enter some text.." 
                />
                {/* <Editor editorState={editorState} readOnly={true} /> */}
            </EditorContainer>
        </>
    )
}