import {
    Grid, makeStyles,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import typesense from "typesense";
import { searchServerConfig } from '../../utils/search/typesense-config';
import TypesenseJobs from "./typesense-jobs";
import BaseButton from '../base-components/base-button';
import firebase from '../../utils/firebase';
import { COMPANIES_COLLECTION, JOBS_COLLECTION } from '../../utils/constants';
import { useEffect, useState } from 'react';
import { setCustomUserClaim, sendTestMail } from '../../utils/server-functions';
import MoveDuplicateJob from './move-duplicate-job';

const extractEmail = (str) => {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    const matches = (str && str.match) ? str.match(emailRegex) : null;
    return matches ? matches[0].toLowerCase() : null;
};


function saveArrayToFile(array, filename) {
    const data = array.join('\n'); // Concatenate array elements with line breaks

    const blob = new Blob([data], { type: 'text/plain' });

    if (window.navigator.msSaveBlob) {
        // For IE and Edge browsers
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // For other browsers
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
}

const useStyles = makeStyles(() => ({
    container: {
        marginTop: 100,
        marginLeft: 30,
    }
}));

export default function DashboardAdmin() {
    const classes = useStyles();
    const client = new typesense.Client(searchServerConfig);
    const [data, setData] = useState([]);
    const [companiesData, setCompaniesData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // const getData = async () => {
        //     let docs = await firebase.firestore().collection(JOBS_COLLECTION).get();

        //     let docsData = [];
        //     docs.docs.forEach(doc =>
        //         docsData.push(
        //             doc.exists ? { id: doc.id, ...doc.data() } : {}
        //         )
        //     );

        //     setData(docsData);
        //     docs = await firebase.firestore().collection(COMPANIES_COLLECTION).get();
        //     docsData = [];
        //     docs.docs.forEach(doc =>
        //         docsData.push(
        //             doc.exists ? { id: doc.id, ...doc.data() } : {}
        //         )
        //     );
        //     setCompaniesData(docsData);
        // }

        // getData();

    }, []);


    const onExtractEmailsButtonClick = () => {
        if (data?.length > 0 && companiesData?.length > 0) {
            const arr = [];
            for (let i = 0; i < companiesData.length; i++) {
                const company = companiesData[i];
                let companyEmails = [];
                for (let j = 0; j < data.length; j++) {
                    const job = data[j];
                    if (job?.companyID == company.id) {
                        const keys = Object.keys(job);
                        keys.forEach(key => {
                            const val = job[key];
                            const email = extractEmail(val);
                            if (email) {
                                companyEmails.push(email);
                            }
                        });
                    }
                }
                arr.push(`${company?.title || ''},"${Array.from(new Set(companyEmails)).join('\n')}"`);
            }
            saveArrayToFile(arr, 'mkt companies.csv');
        }
    }

    const onSendTestMail = async () => {
        await sendTestMail();
    }

    return (
        <Grid container spacing={1} direction="column">
            <Grid item xs={12} container justifyContent="space-between">
                <TypesenseJobs client={client} />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid container spacing={2} direction="row" className={classes.container}>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <BaseButton
                            variant="contained"
                            color="secondary"
                            onClick={onExtractEmailsButtonClick}
                            label={`Extract emails`}
                        >
                        </BaseButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <BaseButton
                            variant="contained"
                            color="secondary"
                            onClick={onSendTestMail}
                            label={`Send test mail`}
                        >
                        </BaseButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                <MoveDuplicateJob />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid container spacing={2} direction="row" className={classes.container}>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <BaseButton
                            variant="contained"
                            color="secondary"
                            onClick={()=> navigate('/admin/batch-update')}
                            label={`Batch Update`}
                        >
                        </BaseButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}