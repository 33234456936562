import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import {
    makeStyles,
    Grid,
    Box
} from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import BaseButton from '../base-components/base-button';
import { useCollectionData } from '../../utils/firebase';
import Heading from '../base-components/typography/heading';
import Table from '../table';
import { adDataConverter } from '../../utils/data-converters';
import Preloader from '../preloader/';
import { ADS_COLLECTION } from '../../utils/constants';

const useStyles = makeStyles(() => ({
    mainPadding: {
        padding: '90px 15px 15px 15px'
    },
    mt: {
        marginTop: '15px'
    },
}));

export default function AdsOverview() {
    const classes = useStyles();
    const navigate = useNavigate();
    const query = useCallback(
        ref => ref
            // .where('status', '==', 'published')
            .orderBy('position', 'asc')
        , []
    );
    const [data, loading, error] = useCollectionData(ADS_COLLECTION, adDataConverter, query);

    if (loading)
        return <Preloader />
    if (error)
        return <p>Something went wrong, please try again later</p>

    const tableHead = [
        { headerName: 'Title', fields: ['title'] },
        { headerName: 'Publish date', fields: ['publishDate'] },
        { headerName: 'Unpublish date', fields: ['unpublishDate'] },
        { headerName: 'Status', fields: ['status'] },
        { headerName: 'Actions', fields: ['actions'] },
    ];

    return (
        <Grid container direction="column" className={classes.mainPadding}>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item xs={12} sm={12} md={12} lg={9}>
                    <Heading color="secondary.dark" variant="h3">Ad Listings</Heading>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Box display="flex" justifyContent="flex-end">
                        <BaseButton onClick={() => navigate('/admin/ads/new')} color="primary" label="Add New Ad" startIcon={<AddCircle />} />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.mt}>
                <Grid item xs={12}>
                    <Table
                        data={data}
                        columns={tableHead}
                        type={'ads'}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}