import { useCallback, useEffect, useState } from "react";
import {
    Grid,
    makeStyles,
} from '@material-ui/core';
import Heading from '../../base-components/typography/heading';
import { JOBS_COLLECTION, countryOptions } from "../../../utils/constants";
import { jobDataConverter } from "../../../utils/data-converters";
import { useCollectionData } from "../../../utils/firebase";
import TableOverview from "../../table";
import Preloader from "../../preloader";
import Filter from "./filter";
import {
    extractDateFromTime
} from "../../../utils/date-utils";

const jobsTableHead = [
    { headerName: 'Title', fields: ['title'] },
    { headerName: 'Company', fields: ['companyName'] },
    { headerName: 'Company email', fields: ['companyEmail'] },
    { headerName: 'Country', fields: ['countryText'] },
    { headerName: 'Service', fields: ['service'] },
    { headerName: 'Publish date', fields: ['publishDate'] },
    { headerName: 'Unpublish date', fields: ['unpublishDate'] },
    { headerName: 'Status', fields: ['status'] },
    { headerName: 'Actions', fields: ['actions'] },
];

const useStyles = makeStyles(() => ({
    mainPadding: {
        padding: '90px 15px 15px 15px'
    },
    mt: {
        marginTop: '15px'
    },
}));

export default function BatchUpdate() {
    const classes = useStyles();
    const [status, setStatus] = useState(null);
    const [statusChanged, setStatusChanged] = useState(null);
    const [startDt, setStartDt] = useState(null);
    const [endDt, setEndDt] = useState(null);

    const query = useCallback(
        ref => {
            ref = ref.orderBy('publishDate', 'desc');
            const endDate = endDt || new Date().setHours(23, 59, 59, 0);
            ref = ref.where('publishDate', '<', new Date(endDate))
            if (startDt) {
                ref = ref.where('publishDate', '>', new Date(extractDateFromTime(startDt)))
            } else {
                ref = ref.limit(10);
            }
            // if (status !== 'all') {
            //     ref = ref.where('status', '==', status);
            // }
            return (ref)
        }
        , [startDt, endDt]
    );
    const [data, loading, error] = useCollectionData(JOBS_COLLECTION, jobDataConverter, query);

    const updateStatus = () => {
        if (status && status !== statusChanged) {
            setStatusChanged(status);
        }
    }

    if (loading) {
        return <Preloader />;
    }

    const jobs = data.map(job => ({
        ...job,
        countryText: countryOptions.find(c => c.id === job.country)?.label || 'Aruba',
    }))

    return (
        <Grid container direction="column" className={classes.mainPadding}>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Heading color="secondary.dark" variant="h3">Job Listings</Heading>
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Filter
                        status={status}
                        setStatus={setStatus}
                        updateStatus={updateStatus}
                        disableUpdateStatus={(!status || status === statusChanged)}
                        startDt={startDt}
                        setStartDt={setStartDt}
                        endDt={endDt}
                        setEndDt={setEndDt}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.mt}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TableOverview
                        data={jobs}
                        columns={jobsTableHead}
                        type="jobs"
                        addIndex={true}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}